<template>
  <v-layout
    align-center
    class="initials-circle"
    :style="{
      minHeight: `${size}px`,
      minWidth: `${size}px`,
      height: `${size}px`,
      width: `${size}px`,
    }"
    @click.stop="$emit('clicked')"
  >
    <v-flex class="text-center">
      <div
        v-if="!loading"
        class="white--text"
        :style="{ fontSize: `${fontSize}px` }"
      >
        <b>{{ initials }}</b>
      </div>
      <v-progress-circular
        v-else
        color="white"
        indeterminate
      ></v-progress-circular>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  props: {
    name: {
      default: "Test Test",
    },
    size: {
      type: Number,
      default: 50,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    initials() {
      const splitted_name = this.name.split(" ");
      let first = "";
      if (splitted_name.length > 0 && splitted_name[0][0]) {
        first = splitted_name[0][0].toUpperCase();
      }

      let last = "";
      if (splitted_name.length > 1 && splitted_name[1][0]) {
        last = splitted_name[1][0].toUpperCase();
      }
      
      return first + last;
    },
    fontSize() {
      return parseInt(this.size / 2.6);
    },
  },
};
</script>

<style lang="scss" scoped>
.initials-circle {
  cursor: pointer;
  background-color: var(--v-primary-base);
  border-radius: 50%;
}
</style>